<template>
	<dashboardPagesLayout>
		<div class="p-inside-manage">
			<div class="page_title">Events</div>
			<button type="button" class="create_btn secondary_btn small" v-ripple @click="redirectToAddEvent">Add an Event</button>
			<div class="wrap_manage_table">
				<div class="top_bar">
					<div class="tabs_section">
						<ul>
							<li :class="{'active': topFilter === 'current'}" @click="changeTopFilter('current')">Current</li>
							<li :class="{'active': topFilter === 'past'}" @click="changeTopFilter('past')">Past</li>
							<li :class="{'active': topFilter === 'drafts'}" @click="changeTopFilter('drafts')">Drafts</li>
						</ul>
					</div>
					<div class="search_section">
						<span class="icon"></span>
						<input type="text" placeholder="Search events" v-model="searchEvents">
					</div>
				</div>

				<eventsTable :dataTable="events"></eventsTable>
			</div>
		</div>

		<BetaTestingDialog 
			v-if="showEventPassphraseDialog" 
			v-model="showEventPassphraseDialog"
			:storage-key="BETA_FEATURES.ADD_EVENT.storageKey"
			:passphrase="BETA_FEATURES.ADD_EVENT.passphrase"
			:title="BETA_FEATURES.ADD_EVENT.title"
			:description="BETA_FEATURES.ADD_EVENT.description"
			:contact-email="BETA_FEATURES.ADD_EVENT.contactEmail"
			@verified="onEventPassphraseVerified"
			@cancelled="showEventPassphraseDialog = false"
		></BetaTestingDialog>
	</dashboardPagesLayout>
</template>


<script>
import dashboardPagesLayout from "@/layouts/dashboardPagesLayout";
import {mapGetters, mapActions, mapMutations} from "vuex";
import eventsTable from "@/components/manage/eventsTable.vue";
import BetaTestingDialog from "@/components/dialogs/betaTestingDialog";
import { BETA_FEATURES } from "@/config/betaTestingPassphrase";

export default {
	name: "manageEvents",
	components: {
		dashboardPagesLayout,
		eventsTable,
		BetaTestingDialog
	},
	data() {
		return {
			searchEvents: '',
			BETA_FEATURES,
			showEventPassphraseDialog: false
		}
	},
	watch: {
		searchEvents() {
			this.getEventList();
		}
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			topFilter: 'manageUploadsEvents/TOP_FILTER',
			events: 'events/EVENTS',
			eventsLoading: 'events/EVENTS_LOADING',
			countEvents: 'events/COUNT_EVENTS',
			currentPage: 'events/CURRENT_PAGE',
			isAdmin: 'isAdmin'
		})
	},
	mounted() {
		this.getEventList();
	},
	methods: {
		...mapActions({
			getEvents: 'events/GET_EVENTS',
		}),
		...mapMutations({
			setRedirectPath: 'SET_REDIRECT_PATH',
			setEventBetaDialog: 'SET_EVENT_BETA_DIALOG'
		}),
		getEventList() {
			let params = {
				page: this.currentPage,
				title: this.searchEvents,
				owner: this.userID
			}
			this.getEvents(params)
				.then(() => {
				})
				.catch(err => console.log(`GET_events, ${err}`))
		},
		changeTopFilter(filter) {
			this.$store.commit('manageUploadsEvents/SET_TOP_FILTER', filter);
		},
		redirectToAddEvent() {
			console.log('redirectToAddEvent called');
			if (localStorage.getItem(this.BETA_FEATURES.ADD_EVENT.storageKey) === 'true') {
				console.log('Has access, redirecting to create event');
				this.$router.push({name: 'createEvent'});
			} else {
				console.log('No access, showing beta dialog');
				this.showEventPassphraseDialog = true;
			}
		},
		onEventPassphraseVerified() {
			this.showEventPassphraseDialog = false;
			this.$router.push({name: 'createEvent'});
		}
	}
}
</script>

<style scoped>

</style>